exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-buy-tsx": () => import("./../../../src/pages/buy.tsx" /* webpackChunkName: "component---src-pages-buy-tsx" */),
  "component---src-pages-comics-tsx": () => import("./../../../src/pages/comics.tsx" /* webpackChunkName: "component---src-pages-comics-tsx" */),
  "component---src-pages-content-tsx": () => import("./../../../src/pages/content.tsx" /* webpackChunkName: "component---src-pages-content-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-templates-comic-tsx": () => import("./../../../src/templates/comic.tsx" /* webpackChunkName: "component---src-templates-comic-tsx" */)
}

